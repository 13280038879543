<template>
  <div>
    <portal to="breadcrumb">
      <Breadcrumb>
        <b-breadcrumb-item active>
          Map
        </b-breadcrumb-item>
      </Breadcrumb>
    </portal>
    <card
      slot="external-body"
      external-card="material-card-content"
      internal-card="material-card"
      style-internal-card="flex:auto"
      style="overflow: visible"
    >
      <div
        id="companyMapContainer"
        slot="header"
        class="map-view"
      >
        <div
          slot="body"
          style="height:85vh;z-index:0;"
        >
          <b-loading
            :is-full-page="false"
            :active="gettingLocations"
            :can-cancel="false"
          />
          <GmapMap
            ref="map"
            :center="mapCenter"
            :zoom="2"
            :options="{
              'minZoom': 2,
              'maxZoom': 20,
              'streetViewControl': false,
              'mapTypeControl': false,
              'clickableIcons': false
            }"
            style="height:85vh;z-index:0;"
          >
            <gmap-polygon
              v-for="(perimeter, index) in perimeters.filter(
                (x) => x.type.dbValue === locationPerimeterType.POLYGON.value.dbValue)"
              :key="index"
              ref="polygon"
              :paths="perimeter.paths"
            />
            <GmapCircle
              v-for="(perimeter) in perimeters.filter((x) =>
                x.type.dbValue === locationPerimeterType.MARKER.value.dbValue
                && x.center.lat && x.center.lng
                && x.radius)"
              :key="perimeter.name"
              :center="{lat: parseFloat(perimeter.center.lat),
                        lng: parseFloat(perimeter.center.lng)}"
              :radius="parseFloat(perimeter.radius)"
              :visible="true"
              :options="{'fillColor':'red', 'fillOpacity':0.2}"
            />
            <gmap-marker
              v-for="(marker, index) in markers"
              :key="index"
              :position="marker.position"
              :icon="{'url': require('@/assets/img/map_icon.svg')}"
              @click="toggleInfoWindow(marker,index)"
            />
            <gmap-info-window
              :position="infoWindowPos"
              :opened="infoWinOpen"
              @closeclick="infoWinOpen=false"
            >
              <div>
                <router-link
                  :to="{ name: 'device', params: { deviceId: infoContent.id, tab: 'location' } }"
                >
                  <strong>{{ infoContent.name }}</strong><br>
                </router-link>
                {{ infoContent.address }}
              </div>
            </gmap-info-window>
          </GmapMap>
        </div>
      </div>
    </card>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { gmapApi } from 'vue2-google-maps';
import card from '@/components/cross/Card.vue';
import locationHelpers from '@/helpers/locationHelpers';
import CompanyMixin from '@/mixins/company';

import bgEnums from '../../../cross/index';

const { reportsNames } = bgEnums.enum.report;
const { planCapabilities } = bgEnums.enum.planCapabilities;
const { locationPerimeterType } = bgEnums.enum.location;

export default {
  name: 'CompanyLocations',
  metaInfo: {
    title: 'Map - Boardgent',
  },
  components: {
    card,
  },
  mixins: [CompanyMixin],
  data() {
    return {
      reportsNames,
      planCapabilities,
      gettingLocations: true,
      infoContent: '',
      infoWindowPos: {
        lat: 0,
        lng: 0,
      },
      infoWinOpen: false,
      currentMidx: null,
      currentMap: null,
      currentMarkers: [],
      totalLocaltions: 0,
      mapCenter: { lat: 0, lng: 0 },
      locationPerimeterType,
    };
  },
  computed: {
    ...mapGetters('companies', { currentCompany: 'getCurrentCompany' }),
    ...mapGetters('seed-map', { seedMap: 'find' }),
    ...mapGetters('employee-profiles', { findDeviceGroupsInStore: 'find' }),
    ...mapState(['mainLayoutStyle']),
    google: gmapApi,
    markers() {
      if (!this.google || !this.currentMap) {
        return [];
      }

      const allDevicesLocations = this.seedMap({
        query: {
          companyId: this.currentCompany.id,
        },
      });
      return this.updatedMarkers(allDevicesLocations);
    },
    perimeters() {
      // The code was temporarily commented while ticket EAW-768 is resolved.
      return [];
      // if (!this.validateIfHasCapability([planCapabilities.GEOFENCING.value])) {
      //   return [];
      // }
      // const allDeviceGroups = this.findDeviceGroupsInStore({
      //   query: {
      //     companyId: this.currentCompany.id,
      //   },
      // });

      // const { data } = allDeviceGroups;
      // const groupsWithPerimeter = data.filter((group) => group.locationBounds.enabled);
      // let perimeters = [];
      // groupsWithPerimeter.forEach((group) => {
      //   perimeters = perimeters.concat(perimeters,
      //     locationHelpers.parsePerimetersFromDb(group.locationBounds.perimeters));
      // });
      // return perimeters;
    },
  },
  async created() {
    await this.fetchApi();
  },
  mounted() {
    this.currentMap = this.$refs.map;
  },
  methods: {
    ...mapActions('seed-map', { seedMapFromAPI: 'find' }),
    ...mapActions('employee-profiles', { findDeviceGroups: 'find' }),
    async fetchApi() {
      if (!this.currentCompany) return;
      await this.fetchDeviceGroups();
      await this.fetchSeedMap();
    },
    async fetchDeviceGroups(skip = 0) {
      let skipDeviceGroups = skip;
      const deviceGroupsAnswer = await this.findDeviceGroups({
        query: {
          companyId: this.currentCompany.id,
          $skip: skipDeviceGroups,
          $limit: 500,
        },
      });
      skipDeviceGroups += 500;
      this.totalLocaltions = deviceGroupsAnswer.total;
      if (deviceGroupsAnswer.total > skipDeviceGroups) {
        await this.findDeviceGroups(skipDeviceGroups);
      }
    },
    async fetchSeedMap(skip = 0) {
      let skipDevices = skip;
      const deviceAnswer = await this.seedMapFromAPI({
        query: {
          companyId: this.currentCompany.id,
          $skip: skipDevices,
        },
      });
      skipDevices += 500;
      this.totalLocaltions = deviceAnswer.total;
      if (deviceAnswer.total > skipDevices) {
        await this.fetchSeedMap(skipDevices);
      }
    },
    toggleInfoWindow(marker, idx) {
      this.infoWindowPos = marker.position;
      this.infoContent = marker.contentInfo;

      if (this.currentMidx === idx) {
        this.infoWinOpen = !this.infoWinOpen;
      } else {
        this.infoWinOpen = true;
        this.currentMidx = idx;
      }
    },
    updatedMarkers(allDevicesLocations) {
      if (allDevicesLocations.data.length === 0) {
        this.gettingLocations = false;
        return [];
      }
      this.gettingLocations = true;
      const bounds = new this.google.maps.LatLngBounds();
      const markers = [];
      allDevicesLocations.data.forEach((device) => {
        const position = locationHelpers.parseLatLng(device.lastLocation);
        const myLatLng = new this.google.maps.LatLng(position.lat, position.lng);
        bounds.extend(myLatLng);
        markers.push({
          position,
          contentInfo: {
            id: device.id,
            name: device.name,
            address: device.address,
          },
        });
      });
      setTimeout(() => {
        this.$refs.map.fitBounds(bounds);
        if (allDevicesLocations.total >= this.totalLocaltions) {
          this.gettingLocations = false;
        }
      }, 500);
      return markers;
    },
  },
};
</script>

<style scoped>
.map-view {
  width: 100%; /* 30% */
  overflow: hidden;
}
</style>
