var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',[_c('portal',{attrs:{"to":"breadcrumb"}},[_c('Breadcrumb',[_c('b-breadcrumb-item',{attrs:{"active":""}},[_vm._v(" Map ")])],1)],1),_c('card',{staticStyle:{"overflow":"visible"},attrs:{"slot":"external-body","external-card":"material-card-content","internal-card":"material-card","style-internal-card":"flex:auto"},slot:"external-body"},[_c('div',{staticClass:"map-view",attrs:{"slot":"header","id":"companyMapContainer"},slot:"header"},[_c('div',{staticStyle:{"height":"85vh","z-index":"0"},attrs:{"slot":"body"},slot:"body"},[_c('b-loading',{attrs:{"is-full-page":false,"active":_vm.gettingLocations,"can-cancel":false}}),_c('GmapMap',{ref:"map",staticStyle:{"height":"85vh","z-index":"0"},attrs:{"center":_vm.mapCenter,"zoom":2,"options":{
            'minZoom': 2,
            'maxZoom': 20,
            'streetViewControl': false,
            'mapTypeControl': false,
            'clickableIcons': false
          }}},[_vm._l((_vm.perimeters.filter(
              (x) => x.type.dbValue === _vm.locationPerimeterType.POLYGON.value.dbValue)),function(perimeter,index){return _c('gmap-polygon',{key:index,ref:"polygon",refInFor:true,attrs:{"paths":perimeter.paths}})}),_vm._l((_vm.perimeters.filter((x) =>
              x.type.dbValue === _vm.locationPerimeterType.MARKER.value.dbValue
              && x.center.lat && x.center.lng
              && x.radius)),function(perimeter){return _c('GmapCircle',{key:perimeter.name,attrs:{"center":{lat: parseFloat(perimeter.center.lat),
                      lng: parseFloat(perimeter.center.lng)},"radius":parseFloat(perimeter.radius),"visible":true,"options":{'fillColor':'red', 'fillOpacity':0.2}}})}),_vm._l((_vm.markers),function(marker,index){return _c('gmap-marker',{key:index,attrs:{"position":marker.position,"icon":{'url': require('@/assets/img/map_icon.svg')}},on:{"click":function($event){return _vm.toggleInfoWindow(marker,index)}}})}),_c('gmap-info-window',{attrs:{"position":_vm.infoWindowPos,"opened":_vm.infoWinOpen},on:{"closeclick":function($event){_vm.infoWinOpen=false}}},[_c('div',[_c('router-link',{attrs:{"to":{ name: 'device', params: { deviceId: _vm.infoContent.id, tab: 'location' } }}},[_c('strong',[_vm._v(_vm._s(_vm.infoContent.name))]),_c('br')]),_vm._v(" "+_vm._s(_vm.infoContent.address)+" ")],1)])],2)],1)])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }